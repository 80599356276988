
/* src/App.css */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --button-hover-color: #2980b9;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* Prevent horizontal scroll */
  font-size: var(--font-size-base);
}

html, body {
  overflow-x: hidden;
  max-width: 100vw;
}

.header {
  text-align: center;
  padding: 2rem;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--font-size-lg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: var(--font-size-base);
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--button-hover-color);
}

input, textarea {
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: var(--font-size-base);
}

input:focus, textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .header {
      font-size: var(--font-size-base);
      padding: 1.5rem;
  }

  .container {
      grid-template-columns: 1fr;
  }

  button {
      width: 100%;
  }
}
