:root {
  --primary-color: #6C63FF;
  --secondary-color: #4CAF50;
  --background-color: #F0F4F8;
  --text-color: #2C3E50;
  --accent-color: #FF6B6B;
  --success-color: #2ECC71;
  --warning-color: #F39C12;
  --error-color: #E74C3C;
  --card-bg: #FFFFFF;
  --input-bg: #EBF0F5;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* General Layout Adjustments */
.App, .container, .form-container, .form {
  padding: 2rem;
  background-color: var(--card-bg);
  border-radius: 10px;
  margin: 0 auto;
}

/* Header */
.header {
  text-align: center;
  padding: clamp(2rem, 5vw, 4rem);
  background: linear-gradient(135deg, #f6f8fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

.header::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 70%);
  transform: rotate(30deg);
  z-index: 1;
}

.header h1 {
  margin: 0;
  font-size: clamp(2rem, 4vw, 3rem);
  color: var(--primary-color);
  position: relative;
  z-index: 2;
}

.header p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: var(--text-color);
  margin-top: 0.5rem;
  position: relative;
  z-index: 2;
}

/* Hero Section */
.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background: linear-gradient(135deg, #4e73df, #1cc88a);
  color: white;
  border-radius: 8px;
  flex-direction: column;
  text-align: center;
}

.hero-content {
  max-width: 100%;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: white;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  max-width: 350px;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .hero-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .hero-image {
    order: -1;
    width: 80%;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .hero-content {
    max-width: 50%;
    text-align: left;
  }
}

/* Subject Section */
.subject-section {
  margin-top: 50px;
  text-align: center;
}

.subject-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.subject-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.subject-card {
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.subject-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subject-card p {
  font-size: 1rem;
  color: #6c757d;
}

.subject-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #4e73df;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.subject-link:hover {
  background-color: #375a7f;
}

/* Navbar */
.navbar {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.navbar-links a:hover {
  text-decoration: none;
}

/* Hamburger Menu Icon */
.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  margin-left: auto;
}

/* Mobile styles for Navbar */
@media (max-width: 768px) {
  .navbar {
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 15px;
  }

  .navbar-links {
      display: none;
      flex-direction: column;
      gap: 10px;
      background-color: var(--primary-color);
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      padding: 15px 0;
  }

  .navbar-links.open {
      display: flex;
  }

  .hamburger {
      display: block;
      color: white;
  }
}

/* Tools Section for Subjects like Math, Science, Software, etc. */
.tools-section {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem 0;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.tool-block {
  background-color: var(--card-bg);
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  flex: 1;
  /* min-width: 180px;
  max-width: 280px; */
  text-align: center;
  transition: all 0.3s ease;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 2px;
}

.tool-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.tool-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.tool-block h2 {
  color: var(--text-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.tool-block p {
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
}

.tool-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 600;
}

.tool-link:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .tools-section {
    flex-direction: column;
    align-items: center;
  }

  .tool-block {
    width: 100%;
    /* max-width: 100%;
    margin-bottom: 1rem; */
  }
}

/* Consistent Form Styles */
.form-container, .form {
  background-color: var(--card-bg);
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  /* width: 100%; */
}

input[type="text"],
input[type="file"],
textarea,
select {
  width: 95%;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--input-bg);
  font-size: 1rem;
  transition: all 0.3s ease;
}

/* Textarea */
textarea {
  padding: 15px;
  border-radius: 5px;
  background-color: var(--input-bg);
  border: 1px solid #ced4da;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  max-width: 95%;
  resize: vertical;
  min-height: 150px; /* Ensures textarea has more rows visible */
}

/* Loading Indicator */
.loading {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100px;
  color: red;
  background-color: var(--card-bg);
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 0 auto;
}

.loading::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 5px solid var(--input-bg);
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Buttons */
.btn-primary {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

/* Utility Classes for Spacing */
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

/* Tooltip Styles */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: var(--text-color);
  color: #fff;
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

[data-tooltip]:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .App {
    padding: 0rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  .form {
    padding: 1.5rem;
  }

  button {
    width: 100%;
  }
}

/* Improve readability of generated content */
.generated-content {
  background-color: var(--card-bg);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  line-height: 1.8;
  font-size: 1.1rem;
}

.generated-content strong {
  color: var(--primary-color);
  font-weight: 600;
}

.generated-content em {
  font-style: italic;
  color: var(--secondary-color);
}

.generated-content br {
  display: block;
  content: "";
  margin-top: 0.75rem;
}

/* Reports Section */
.report, .generated-content {
  animation: fadeInUp 0.5s ease-out;
  margin-top: 3rem;
}

.report {
  background-color: var(--card-bg);
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.report:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--input-bg);
}

/* PDF Button */
.pdf-button {
  background-color: var(--secondary-color);
  font-size: 0.9rem;
  padding: 0.75rem 1.5rem;
}

.pdf-button:hover {
  background-color: #45A049;
}

/* Add this class to ensure text is black during PDF generation */
.pdf-text-black {
  color: #000000 !important;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

select, button {
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  outline: none;
  cursor: pointer;
}

button {
  background-color: #3182ce;
  color: #fff;
  border: none;
  margin: 15px 0;
}

select {
  margin: 15px 0;
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.report {
  background-color: #f8f9fa;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.report-header {
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
}

.report-content {
  font-size: 16px;
  color: #495057;
}

.pdf-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.pdf-button:hover {
  background-color: #0056b3;
}
