/* PdfToWord.css */

:root {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --button-background: #4e73df;
    --button-hover-background: #375a7f;
    --text-color: #333;
    --input-bg: #f0f4f8;
  }
  
  .container {
    max-width: 600px;
    margin: 40px auto;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1 {
    color: var(--primary-color);
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .upload-box input[type="file"] {
    display: none; /* Hide the default file input */
  }
  
  .file-label {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--input-bg);
    color: var(--text-color);
    border: 2px dashed var(--primary-color);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    width: 100%;
    max-width: 250px;
    margin-bottom: 15px;
  }
  
  .file-label:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--button-background);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 250px;
  }
  
  button:hover {
    background-color: var(--button-hover-background);
  }
  
  .download-link {
    margin-top: 20px;
  }
  
  .download-link a {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .download-link a:hover {
    color: var(--button-hover-background);
  }
  