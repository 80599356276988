
:root {
  --primary-color: #0b0b0c;
  --secondary-color: #08080804;
  --background-color: #f0f3f6;
  --text-color: #2c3e50;
  --transition: all 0.3s ease;
}

body {
  margin: 0;
  font-family: 'Inter', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

/* Use a consistent width across all pages */
.container {
  padding: 0 1rem;
  margin: 0 auto;
}

.homepage-container {
  max-width: 100%; /* Ensure it also uses full width */
  margin: 0 auto;
  padding: 0.5rem;
}

.header {
  text-align: center;
  padding: clamp(2rem, 5vw, 4rem);
  border-radius: 0 0 20px 20px;
  background: linear-gradient(135deg, #f6f8fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

.header::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
  transform: rotate(30deg);
  z-index: 1;
}

.header h1 {
  margin: 0;
  font-size: clamp(2rem, 4vw, 3rem);
  color: var(--primary-color);
  position: relative;
  z-index: 2;
}

.header p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: var(--text-color);
  margin-top: 0.5rem;
  position: relative;
  z-index: 2;
}

.tools-section {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.tool-block {
  background-color: white;
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 180px;
  max-width: 280px;
  text-align: center;
  transition: var(--transition);
}

.tool-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.tool-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.tool-block h2 {
  color: black;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.tool-block p {
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
}

.tool-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50px;
  text-decoration: none;
  transition: var(--transition);
  font-weight: 600;
}

.tool-link:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

/* Ensure consistent width for all pages */
.subpage-container {
  max-width: 100%; /* Full width */
  padding: 0 1rem; /* Maintain padding for spacing */
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .tools-section {
    flex-direction: column;
    align-items: center;
  }

  .tool-block {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

/* Hero Section */
.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background: linear-gradient(135deg, #4e73df, #1cc88a);
  color: white;
  border-radius: 8px;
  flex-direction: row;
}

.hero-content {
  max-width: 50%;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: white;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.subject-section {
  margin-top: 50px;
  text-align: center;
}

.subject-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.subject-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.subject-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.subject-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.subject-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subject-card p {
  font-size: 1rem;
  color: #6c757d;
}

.subject-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #4e73df;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.subject-link:hover {
  background-color: #375a7f;
}

/* Navbar */
.navbar {
  background-color: #343a40;
  color: white;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar a {
  color: white;
  padding: 0 15px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #495057;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-content {
    max-width: 100%;
    padding: 0;
  }

  .hero-image img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}
